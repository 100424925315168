import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import logo from './full-logo.svg'

export default function NavComponent(props) {  
    return (
        <Navbar bg="light" variant="light">
        <Container>
          <Navbar.Brand href="https://senseye.co/">
            <img
              alt="Senseye logo"
              src={logo}
              width="150"
              height="40"
              className="d-inline-block align-top"
            />{''}
          </Navbar.Brand>
        </Container>
      </Navbar>
    );
}