import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import Container from 'react-bootstrap/Container';
import NavComponent from "./layout/Navbar"; 
import './App.css';

const specification = require("./senseye-api-swagger.json");

function App() {
  return (
    <>
      <NavComponent />
      <Container>
        <SwaggerUI spec={specification}/>
      </Container>
    </>
  );
}

export default App;
